import React from "react";
import BotResponse from "./BotResponse";

const IntroSection = () => {
  return (
    <div id="introsection">
      <h1>
        勇麦Bot
        <BotResponse response="--ChatGPT3.5模型" />
      </h1>
      
      <p>
        Tips:
        <ul>
          <li>此Bot初衷为个人体验chatGPT使用，无法保证访问质量与长期能用；输入/输出的字数限制在500字以内；</li>
          <li>此Bot可以多次对话，但仅支持单轮对话（类似单次问答模式），不支持根据上下文语义连续对话，仅对您本次发送的内容进行回复；</li>
          <li>更长的字数与连续对话版本的Chatgpt，请在yongmai.xyz，注册并登录后体验；</li>
        </ul>
      </p>
      
      <p>
        Say goodbye to endless searching and typing.
      </p>
    </div>
  );
};

export default IntroSection;
